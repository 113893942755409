import { gql } from '@apollo/client';

export const CHECK_USER_PIN = gql`
  query CheckUserPin($userId: String!, $operatorPin: String!) {
    checkUserPin(userId: $userId, operatorPin: $operatorPin) {
      status
      code
      data
      error
    }
  }
`;

export const GET_OPERATORS = gql`
  query GetUsers {
    getUsers(filterRole: ["Operator", "Warehouse Manager"], limit: 50) {
      status
      code
      count
      error
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        signature
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
