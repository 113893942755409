export const enum ROLES {
  SUPER_ADMIN = 'Superadmin',
  OWNER = 'Owner',
  SUPERVISOR = 'Supervisor',
  MANAGER = 'Manager',
  DRIVER = 'Driver',
  OPERATOR = 'Operator',
  OPERATOR_DEVICE = 'Operator Device',
  WAREHOUSE_MANAGER = 'Warehouse Manager',
}
